import React, { useState, useEffect } from "react";
import SearchComponent from "./components/SearchComponent";
import ResultsComponent from "./components/ResultsComponent";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from 'react-router-dom';
import CardContainer from "./components/CardContainer";
import Indeed from "./views/Indeed";

const PASSWORD = "wable_test";

const App = () => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastSearchTime, setLastSearchTime] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [searchTime, setSearchTime] = useState("");
  const [error, setError] = useState(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryParamValue = params.get("indeed");

  useEffect(() => {
    const loginTimestamp = localStorage.getItem("loginTimestamp");
    const currentTime = new Date().getTime();

    if (loginTimestamp && currentTime - parseInt(loginTimestamp) <= 3600000) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleSearch = (data, errorMessage, searchTime) => {
    if (errorMessage) {
      setError(errorMessage);
      setResults([]); // Clear previous results
      return;
    }

    const currentTime = new Date().getTime();
    if (currentTime - lastSearchTime < 60000) {
      setError("please wait 1 minute for api refresh");
      return;
    }
    setLastSearchTime(currentTime);
    setResults(data || []); // Use an empty array as a fallback
    setError(null); // Clear any previous error
    setSearchTime(searchTime);
  };

  const handleLogin = () => {
    if (password === PASSWORD) {
      const loginTimestamp = new Date().getTime();
      localStorage.setItem("loginTimestamp", loginTimestamp.toString());
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password!");
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="container mt-5">
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="btn btn-primary mt-3" onClick={handleLogin}>
          Login
        </button>
      </div>
    );
  }

  return (
    <>
      {queryParamValue ? (
        <Indeed />
      ) : (
        <>
          <LoadingOverlay active={isLoading} spinner text="Loading...">
            <div className="container mt-5">
              <SearchComponent
                onSearch={handleSearch}
                setIsLoading={setIsLoading}
              />
              {error ? (
                <div className="alert alert-danger mt-3">{error}</div>
              ) : (
                <>
                  <ResultsComponent
                    results={results}
                    searchTime={searchTime}
                    ß
                  />
                  <CardContainer data={results} />
                </>
              )}
            </div>
          </LoadingOverlay>
        </>
      )}
    </>
  );
};

export default App;
