import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function DisplayData({ data }) {
  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderData(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof data === 'object' && data !== null) {
      return (
        <div>
          {Object.keys(data).map((key) => (
            <div key={key}>
              <h3>{key}</h3>
              {renderData(data[key])}
            </div>
          ))}
        </div>
      );
    } else {
      return <p>{data}</p>;
    }
  };

  return (
   <>
    <div className="card" style={{ width: '30rem', maxHeight: '500px', overflowY: 'auto' }}>
      <div className="card-header">Data</div>
      <div className="card-body">
        {renderData(data)}
      </div>
    </div>
    
    </>
  );
}

export default DisplayData;
