import React, { useState } from "react";
import axios from "axios";

const API_ENDPOINT = "https://api.peopledatalabs.com/v5/person/search"; // for sake of simplicity, we'll hardcode the API key here

const SearchComponent = ({ onSearch, setIsLoading }) => {
  const [query, setQuery] = useState("");

  const handleSearch = async () => {
    try {
      const startTime = new Date().getTime();
      setIsLoading(true); // Start loading
      const requestData = {
        size: 5,
        query: {
          bool: {
            must: [
              { term: { location_country: "united states" } },
              { term: { location_region: "utah" } },
            //    { term: { birth_date: "1974-06-17" } },
              { wildcard: { full_name: `*${query}*` } }, // Use wildcard query for full_name
            //   { exists: { field: "phone_numbers" } },
            ],
          },
        },
      };
      const response = await axios.post(API_ENDPOINT, requestData, {
        headers: {
          "X-Api-Key":
            "f78ac57cf67193a2d138bc47b2492b22affa31ca5f950f73aa80662c5ca02983", // Replace with your API key
          "Content-Type": "application/json",
        },
      });
      const endTime = new Date().getTime(); // Capture end time after the call is completed
      const searchTime = endTime - startTime; // Calculate the difference

      onSearch(response.data.data, null, searchTime); // Pass the 'data' property of the response
    } catch (error) {
      console.error("Error fetching data:", error);
      onSearch(
        null,
        error.response
          ? error.code + ": " + JSON.stringify(error.response.data)
          : "An error occurred"
      ); // Pass error message
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchComponent;
