import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function Timer({ start }) {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [start]);

  return <p>Run Time: {seconds} seconds</p>;
}

function convertJSONToCSV(data) {
  const replacer = (key, value) => (value === null ? "" : value);
  const header = Object.keys(data[0]);
  const csv = [
    header.join(","),
    ...data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join("\r\n");

  return csv;
}

function Indeed() {
  const [query, setQuery] = useState("Residential Cleaner");
  const [location, setLocation] = useState("Miami, FL");
  const [experienceLevel, setExperienceLevel] = useState("entry_level");
  const [sort, setSort] = useState("date");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [startTimer, setStartTimer] = useState(false);

  const handleAPICall = async () => {
    setIsLoading(true);
    setStartTimer(true);

    const bodyData = {
      query: query,
      location: location,
      experienceLevel: experienceLevel,
      sort: sort,
    };

    const apiUrl =
      "https://api.apify.com/v2/acts/adrian_horning~best-indeed-jobs-scraper/run-sync-get-dataset-items?token=apify_api_zlsXZEQNJpWho9Z7VjkcJYiT2WHF353COwx9";

    // Function to fetch data
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        if (response.ok) {
          const data = await response.json();
          setResults(data);
          setIsLoading(false); // Stop the loader when data is received
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    };

    // Start the polling process
    const startPolling = () => {
        console.log("start polling");
      const maxAttempts = 10;
      let attempts = 0;

      const pollInterval = setInterval(async () => {
        attempts += 1;

        // Fetch data
        await fetchData();

        // For this example, we stop polling after maxAttempts
        // In a real-world scenario, you'd also want to check the response to see if the process is complete
        if (attempts >= maxAttempts || results.length > 0) {
          clearInterval(pollInterval);
          setIsLoading(false); // Make sure to stop the loader
          startTimer(false);
        }
      }, 30000); // Poll every 30 seconds
    };

    // Start the process
    startPolling();
  };

  const handleExport = () => {
    const csv = convertJSONToCSV(results);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "export.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="container mt-5">
      <h2>API Input Form</h2>
      <div className="form-group">
        <label>Query:</label>
        <input
          type="text"
          className="form-control"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Location:</label>
        <input
          type="text"
          className="form-control"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Experience Level:</label>
        <select
          className="form-control"
          value={experienceLevel}
          onChange={(e) => setExperienceLevel(e.target.value)}
        >
          <option value="entry_level">Entry Level</option>
          <option value="mid_level">Mid Level</option>
          <option value="senior_level">Senior Level</option>
        </select>
      </div>
      <div className="form-group">
        <label>Sort:</label>
        <select
          className="form-control"
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <option value="date">Date</option>
          <option value="relevance">Relevance</option>
        </select>
      </div>
      <button
        className="btn btn-primary"
        onClick={handleAPICall}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            &nbsp;Loading...
          </>
        ) : (
          "Submit"
        )}
      </button>

      <Timer start={startTimer} />

      {results.length > 0 && (
        <div>
          <h3>Results</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Location</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {results.map((item, index) => (
                <tr key={index}>
                  <td>{item.displayTitle}</td>
                  <td>{item.location}</td>
                  <td>{item.company}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="btn btn-secondary" onClick={handleExport}>
            Export
          </button>
        </div>
      )}
    </div>
  );
}

export default Indeed;
