import React from "react";

const ResultsComponent = ({ results, searchTime }) => {
  if (!results || results.length === 0 || !Array.isArray(results)) {
    return <div>No results found.</div>;
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">Search Analytics</h5>
        <p className="card-text">Total found: {results.length}</p>
        {searchTime && <p className="card-text">Search time: {searchTime}ms</p>}
        {/* You can add more analytic data here */}
      </div>
    </div>
  );
};

export default ResultsComponent;
