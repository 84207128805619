import React from 'react';
import DisplayData from './DisplayData'; // Assuming DisplayData is in the same directory
 
function CardContainer({ data }) {
  return (
    <div className="container mt-5">
      <div className="row">
        {data.map((item, index) => (
          <div key={index} className="col-12 col-md-6 mb-4">
            <DisplayData data={item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardContainer;
